// Generated by Framer (d2515d1)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFontsFromSharedStyle, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle1 from "../css/CrYU809cs";
import * as sharedStyle from "../css/Ph8QxrYmo";

const cycleOrder = ["OAjDvF674"];

const serializationHash = "framer-mSCe1"

const variantClassNames = {OAjDvF674: "framer-v-1q9jugo"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({content, heading, height, id, width, ...props}) => { return {...props, onGRpzCHs: content ?? props.onGRpzCHs ?? "Content", yPPcLoUhM: heading ?? props.yPPcLoUhM ?? "Heading"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;heading?: string;content?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, yPPcLoUhM, onGRpzCHs, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "OAjDvF674", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className, sharedStyle1.className]

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1q9jugo", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"OAjDvF674"} ref={ref ?? ref1} style={{...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.h5 className={"framer-styles-preset-1l6gutl"} data-styles-preset={"Ph8QxrYmo"} style={{"--framer-text-alignment": "left"}}>Yenilikçi Girişimciler</motion.h5></React.Fragment>} className={"framer-xqnkbx"} data-framer-name={"Heading"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"WL0fTj6n9"} style={{"--framer-paragraph-spacing": "0px"}} text={yPPcLoUhM} verticalAlignment={"top"} withExternalLayout/><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-rae948"} data-styles-preset={"CrYU809cs"} style={{"--framer-text-alignment": "left"}}>Content</motion.p></React.Fragment>} className={"framer-zf0olg"} data-framer-name={"Content"} fonts={["Inter"]} layoutDependency={layoutDependency} layoutId={"H4Sid5Vqy"} style={{"--framer-paragraph-spacing": "4px"}} text={onGRpzCHs} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-mSCe1.framer-1q0xedj, .framer-mSCe1 .framer-1q0xedj { display: block; }", ".framer-mSCe1.framer-1q9jugo { align-content: flex-start; align-items: flex-start; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 12px; height: min-content; justify-content: flex-start; overflow: visible; padding: 0px; position: relative; width: 284px; }", ".framer-mSCe1 .framer-xqnkbx, .framer-mSCe1 .framer-zf0olg { flex: none; height: auto; position: relative; white-space: pre-wrap; width: 100%; word-break: break-word; word-wrap: break-word; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-mSCe1.framer-1q9jugo { gap: 0px; } .framer-mSCe1.framer-1q9jugo > * { margin: 0px; margin-bottom: calc(12px / 2); margin-top: calc(12px / 2); } .framer-mSCe1.framer-1q9jugo > :first-child { margin-top: 0px; } .framer-mSCe1.framer-1q9jugo > :last-child { margin-bottom: 0px; } }", ...sharedStyle.css, ...sharedStyle1.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 70
 * @framerIntrinsicWidth 284
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","auto"]}}}
 * @framerVariables {"yPPcLoUhM":"heading","onGRpzCHs":"content"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerSiyunVXBf: React.ComponentType<Props> = withCSS(Component, css, "framer-mSCe1") as typeof Component;
export default FramerSiyunVXBf;

FramerSiyunVXBf.displayName = "Audience";

FramerSiyunVXBf.defaultProps = {height: 70, width: 284};

addPropertyControls(FramerSiyunVXBf, {yPPcLoUhM: {defaultValue: "Heading", displayTextArea: false, title: "Heading", type: ControlType.String}, onGRpzCHs: {defaultValue: "Content", displayTextArea: true, title: "Content", type: ControlType.String}} as any)

addFonts(FramerSiyunVXBf, [{explicitInter: true, fonts: [{family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F", url: "https://framerusercontent.com/assets/5vvr9Vy74if2I6bQbJvbw7SY1pQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116", url: "https://framerusercontent.com/assets/EOr0mi4hNtlgWNn9if640EZzXCo.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+1F00-1FFF", url: "https://framerusercontent.com/assets/Y9k9QrlZAqio88Klkmbd8VoMQc.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0370-03FF", url: "https://framerusercontent.com/assets/OYrD2tBIBPvoJXiIHnLoOXnY9M.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF", url: "https://framerusercontent.com/assets/JeYwfuaPfZHQhEG8U5gtPDZ7WQ.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD", url: "https://framerusercontent.com/assets/vQyevYAyHtARFwPqUzQGpnDs.woff2", weight: "400"}, {family: "Inter", source: "framer", style: "normal", unicodeRange: "U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB", url: "https://framerusercontent.com/assets/b6Y37FthZeALduNqHicBT6FutY.woff2", weight: "400"}]}, ...getFontsFromSharedStyle(sharedStyle.fonts), ...getFontsFromSharedStyle(sharedStyle1.fonts)], {supportsExplicitInterCodegen: true})